.english{
    display: flex;
    max-width: 1000px;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    background: #FFFFFF;
    width: 60%;
    margin: 0 auto;
    font-family: 'popins', sans-serif !important;
}
.english h6{
    margin-top: 28px;
    /* font-family: 'DM Sans'; */
    padding: 2px 80px;
    border-radius: 50px;
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 30px;
    background-color: #FCAF3F;
    text-align: center;
}
.english .intro{
    text-align: center;
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 48px;
    color: #000000;
    margin-bottom: 0px;
}
.english p{
    text-align: center;
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #6F6C90;
    margin: 30px 0;
}

.registration{
    width: 100%;
    
    
    margin-top: 30px;
    margin-bottom: 30px;
}
.registration label{
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #6F6C90;
    margin-right: 10px;
    margin-bottom: 10px;
    
}
.registration label span{
    color: red;
}
.registration input{
    width: 100%;
    height: 50px;
    border: 1px solid #6F6C90;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 10px;
    outline: none;
    margin-bottom: 30px;
}
.registration input:focus{
    border: 1px solid #FCAF3F;
}
.registration input::placeholder{
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #6F6C90;
}
.registration input[type="submit"]{
    width: 100%;
    height: 50px;
    background: #FCAF3F;
    border-radius: 8px;
    border: none;
    outline: none;
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #FFFFFF;
    cursor: pointer;
}
.registration button{
    width: 100%;
    height: 50px;
    background: #FCAF3F;
    border-radius: 8px;
    border: none;
    outline: none;
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 30px;
    color: #FFFFFF;
    cursor: pointer;
}
.cart{
    width: 60%;
    
    margin-bottom: 50px;
    padding: 40px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-radius: 30px;
}
.cart h3{
    /* font-family: 'DM Sans'; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 15px;
}
.cart h5{
    font-family: "Poppins", Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #000000;
    margin-bottom: 10px;
}
.cart img{
    width: 16px;
    display: inline !important;
}
.error{
    color: red;
    font-size: 13px;
    margin-bottom: 10px;
}

@media screen and (max-width: 768px){
    .english{
        width: 100%;
    }
    .english h3{
        font-size: 25px !important;
        line-height: 40px !important;
    }
    .english p{
        font-size: 14px !important;
        line-height: 20px !important;
        margin: 10px !important;
    }
    .cart{
        width: 100%;
    }
    .english h6{
        margin: 0;
        margin-bottom: 10px !important;
        padding: 2px 50px;
    }
}


@media screen and (min-width: 769px) and (max-width: 1024px){
    .english{
        width: 100%;
    }
    .english h3{
        font-size: 30px;
    }
    .english p{
        font-size: 18px;
    }
    .cart{
        width: 100%;
    }
}



