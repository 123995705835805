.lesson {
  display: flex;

  align-items: center;
  flex-direction: column;
  padding: 30px;
  background: #ffffff;
  width: 60%;
  margin: 0 auto;
  font-family: "popins", sans-serif !important;
}
.lesson h6 {
  margin-top: 28px;
  /* font-family: 'DM Sans'; */
  padding: 2px 60px;
  border-radius: 10px;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 500;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 30px;
  background-color: #fc3f4e;
}
.lesson h3 {
  text-align: center;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 600;
  font-size: 38px;
  
  color: #000000;
  margin-bottom: 0px;
}
.english p {
  text-align: center;
  /* font-family: 'DM Sans'; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #6f6c90;
  margin: 30px 0;
}
.elementor{
    
    border-radius: 20px;
}
/* video elementor ratio should be 500 : 281 */

@media screen and (max-width: 768px) {
  .lesson {
    width: 100%;
  }
  .lesson h3 {
    font-size: 28px;
    padding-bottom: 28px;
  }
  .lesson h6 {
    font-size: 14px;

  }
  .elementor{
    width: 370px;
    height: 208px;
  }
  
}


@media screen and (min-width: 768px) and (max-width: 1024px) {

    .elementor{
        width: 500px;
        height: 281px;
    }
    .lesson h3{
        font-size: 18px;
        padding-bottom: 28px;
    }

}

@media screen and (min-width: 769px) {
  .elementor{
    width: 500px;
    height: 281px;
  }
  .lesson h3{
    font-size: 24px;
    padding-bottom: 28px;
  }
}

@media screen and (min-width: 1024px) {
  .elementor{
    width: 700px;
    height: 394px;
  }
    h3{
        font-size: 14px;
    }
}
