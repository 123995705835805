.home-component {
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: space-between;
}

.home-component .left {
    width: 55%;
    background: #F3F1FF;
    padding: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.home-component .left .sliders {
    width: 480px;
    background: #FFFFFF;
    border: 0.858968px solid #EFF0F7;
    box-shadow: 0px 3.43587px 18.8973px rgba(41, 44, 50, 0.04);
    border-radius: 16px;
}

.home-component .left .sliders .one-slide {
    width: 100%;
    height: 560px;
    padding: 50px;
    text-align: center;
}

.home-component .left .sliders .one-slide img {
    margin-bottom: 15px;
}

.home-component .left .sliders .one-slide h2 {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28px;
    color: #170F49;
}

.home-component .left .sliders .one-slide p {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #6F6C90;
    margin: 30px 0;
}

.home-component .left .socials {
    width: 100%;
    margin-top: 50px;
    text-align: center;
}

.home-component .left .socials h3 {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #6F6C90;
}

.home-component .left .socials .bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.home-component .left .socials .bottom img {
    margin: 0 10px;
}

.swiper-pagination {
    bottom: 50px !important;
}

.swiper-pagination-bullet {
    width: 14px !important;
    height: 14px !important;
    background: #D9DBE9;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #4A3AFF;
}

.home-component .right {
    width: 45%;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px;
}

.home-component .right form {
    width: 400px;
    display: flex;
    flex-direction: column;
    margin-top: 40px;
}

.home-component .right form h1 {
    text-align: center;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 34px;
    line-height: 46px;
    color: #170F49;
}

.home-component .right form p {
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    text-align: center;
    color: #6F6C90;
    margin-bottom: 40px;
}

.home-component .right form input {
    width: 100%;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 46px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #6F6C90;
    outline: none;
    padding: 10px 20px;
    margin-bottom: 25px;
}

.home-component .right form select {
    width: 100%;
    height: 66px;
    background: #FFFFFF;
    border: 1px solid #EFF0F7;
    box-shadow: 0px 2px 6px rgba(19, 18, 66, 0.07);
    border-radius: 46px;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 20px;
    color: #6F6C90;
    outline: none;
    padding: 10px 20px;
    margin-bottom: 25px;
}

.home-component .right form button {
    width: 100%;
    height: 67px;
    background: #4A3AFF;
    box-shadow: 0px 8px 22px rgba(74, 58, 255, 0.26);
    border-radius: 86px;
    border: none;
    font-family: sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 20px;
    color: #FFFFFF;
    cursor: pointer;
}

@media (max-width: 1400px) {
    .home-component .left {
        width: 55%;
        padding: 30px;
    }
    .home-component .right {
        width: 45%;
        padding: 30px;
    }
    .home-component .right form {
        width: 400px;
        margin-top: 40px;
    }
    .home-component .right form h1 {
        font-size: 28px;
        line-height: 36px;
    }
    .home-component .right form input {
        height: 56px;
        border-radius: 36px;
        padding: 10px 20px;
        margin-bottom: 20px;
    }
    .home-component .right form select {
        height: 56px;
        border-radius: 36px;
        margin-bottom: 20px;
    }
    .home-component .right form button {
        height: 57px;
        border-radius: 36px;
    }
}

@media (max-width: 1200px) {
    .home-component .left {
        width: 55%;
        padding: 20px;
    }
    .home-component .right {
        width: 45%;
        padding: 20px;
    }
}

@media (max-width: 992px) {
    .home-component {
        min-height: 100vh;
        flex-direction: column-reverse;
    }
    .home-component .left {
        width: 100%;
        padding: 50px;
    }
    .home-component .right {
        width: 100%;
        padding: 50px;
    }
    .home-component .right form {
        width: 100%;
        margin-top: 40px;
    }
}

@media (max-width: 576px) {
    .home-component .right .logo {
        width: 150px;
    }
    .home-component .left {
        width: 100%;
        padding: 20px;
    }
    .home-component .right form {
        margin-top: 20px;
    }
    .home-component .right {
        width: 100%;
        padding: 20px;
    }
    .home-component .left .sliders {
        width: 100%;
    }
    .home-component .left .sliders .one-slide {
        height: 500px;
        padding: 20px;
    }
    .home-component .left .socials .bottom img {
        width: 100px;
        margin: 0 5px;
    }
}